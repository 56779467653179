import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import languageStyles from "./language.module.scss"
import config from "../../../utils/siteConfig"


var DE = config.local_DE
var EN = config.local_EN
var languageNameDict = {}
languageNameDict[EN] = "English (United States)"
languageNameDict[DE] = "Deutsch (German)"




const languageClick = (e, language) => {
  e.preventDefault()
  changeLocale(language)
}

const Language = () => (
  // <div>

  <IntlContextConsumer>
    {({ languages, language: currentLocale }) =>
      <a>
        {languageNameDict[(languageNameDict[currentLocale] === "English (United States)" ? EN : DE)]}<span className="sr-only">(current)</span>
      </a>
    }
  </IntlContextConsumer>

  //  </div> 
)

export default Language